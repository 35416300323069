import { Box, Button, Center, Grid, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import HeroComponent from "../components/HeroComponent";
import Layout from "../components/Layout";
import useHover from "../hooks/useHover";

const MotionText = motion(Text);

const ContactUsPage = ({ location }) => {
  const [heroTextRef, isHeroTextHovered] = useHover();
  const scrollHere = useRef(null);

  return (
    <Layout location={location}>
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <Box
        as="section"
        position="relative"
        flex="1"
        height="100%"
        background="#333333"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        minH="85vh"
        maxH="672px"
      >
        <Box
          position="relative"
          zIndex="5"
          px={{ base: "24px", md: "6rem" }}
          textAlign="center"
        >
          <Box
            display="flex"
            color="#FFFFFF"
            fontSize={{ base: "64px", md: "96px", lg: "120px" }}
          >
            <MotionText
              ref={heroTextRef}
              onClick={() => {
                scrollHere.current.scrollIntoView({ behavior: "smooth" });
                window.scrollTo({
                  top: 600,
                  behavior: "smooth",
                });
              }}
              animate={{
                opacity: 1,
                transition: { duration: 1.2, delay: 0 },
              }}
              initial={{ opacity: 0 }}
              whileHover={{
                y: -12,
                transition: {
                  ease: "easeInOut",
                  duration: 0.6,
                  repeat: Infinity,
                  repeatType: "mirror",
                },
              }}
              style={{
                color: "#C3F9E0",
                width: "100%",
                position: "relative",
                fontFamily: "Antonio",
                cursor: "pointer",
              }}
            >
              Contact Us
            </MotionText>
          </Box>
        </Box>
        <Box
          position="relative"
          zIndex="5"
          display="flex"
          justifyContent="center"
          textAlign="center"
          mb="12"
          fontSize={{ base: "20px", md: "24px", lg: "32px" }}
        >
          <motion.span
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 1.2, delay: 0.6, ease: "easeInOut" },
            }}
            initial={{ opacity: 0, y: -80 }}
            style={{
              position: "relative",
              fontFamily: "Montserrat",

              lineHeight: "40px",
              color: "#ffffff",
              fontWeight: 600,
            }}
          >
            Interested in partnering with us?
            <br />
            Click start so we can serve you better
          </motion.span>
        </Box>
        <Box
          position="relative"
          zIndex="5"
          display="flex"
          justifyContent="center"
          textAlign="center"
        >
          <Button
            colorScheme="primary"
            borderRadius="4px"
            boxShadow="none"
            p="24px"
            color="secondary.500"
            fontWeight="bold"
            onClick={() =>
              window.open(
                "https://gahhhvrj1ej.typeform.com/to/ii8ZVyFd",
                "_blank"
              )
            }
          >
            Start inquiry
          </Button>
        </Box>
        <Box>
          <Box
            position="absolute"
            zIndex="4"
            width="100%"
            height="100%"
            top="0"
            left="0"
            right="0"
            bottom="0"
            opacity="0.5"
            backgroundColor={isHeroTextHovered ? "secondary.700" : "#000000"}
            backgroundBlendMode={isHeroTextHovered ? "multiply" : "initial"}
            transition="2s all ease-in-out"
          />
          <HeroComponent>
            <Box
              height="100%"
              backgroundImage="url('/img/ae-contact-hero.jpg')"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
            />
          </HeroComponent>
        </Box>
      </Box>

      <Box
        ref={scrollHere}
        as="section"
        backgroundColor="primary.500"
        className="wrapper-xl"
        position="relative"
        py={{ base: 16, md: 24 }}
        my="24"
        mt={{ base: "80px", md: "120px", lg: "154px" }}
        borderRadius="16px"
        px="40px"
      >
        <Center
          mx="-40px"
          top="-3rem"
          position="absolute"
          textAlign="center"
          width="100%"
        >
          <Text
            as="h1"
            color="secondary.500"
            letterSpacing="-5px"
            fontSize={{ base: "56px", md: "64px", lg: "80px" }}
            lineHeight={{ base: "64px", md: "72px", lg: "88px" }}
            mb="4"
          >
            Reach us directly
          </Text>
        </Center>
        <Center mb="12" flexDir="column" textAlign="center">
          <Text mb="4" as="h3" color="#000000" className="ae-title-two">
            Come say hello!
          </Text>
          <Text
            width={{ base: "100%", md: "65%", lg: "50%" }}
            as="p"
            className="ae-desc-one"
          >
            We’d love to chat with you, answer any questions you may have and
            see how we can help you engage your people through impactful
            experiences.
          </Text>
        </Center>
        <Grid
          gridTemplateColumns={{ base: "none", md: "repeat(4,1fr)" }}
          gridTemplateRows={{ base: "repeat(4,auto)", md: "none" }}
          gridColumnGap={{ base: 0, md: "80px" }}
          gridRowGap={{ base: "40px", md: 0 }}
          alignItems="center"
        >
          <Box
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="flex-start"
            backgroundColor="secondary.500"
            borderRadius="8px"
            p="24px"
            textAlign="center"
            height="100%"
          >
            <img
              draggable="false"
              src="/img/ae-contact-mail.png"
              alt="Email us"
            />
            <Text color="#ffffff" my="3">
              Leave us a message
            </Text>
            <Text color="#ffffff" fontWeight="bold" fontSize="18px">
              info@aevias.com
            </Text>
          </Box>
          <Box
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="flex-start"
            backgroundColor="secondary.500"
            borderRadius="8px"
            p="24px"
            textAlign="center"
            height="100%"
          >
            <img
              draggable="false"
              src="/img/ae-contact-phone.png"
              alt="Call us"
            />
            <Text color="#ffffff" my="3">
              Call us
            </Text>
            <Text color="#ffffff" fontWeight="bold" fontSize="18px">
              604-566-8031
            </Text>
          </Box>
          <Box
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="flex-start"
            backgroundColor="secondary.500"
            borderRadius="8px"
            p="24px"
            textAlign="center"
            height="100%"
          >
            <img
              draggable="false"
              src="/img/ae-contact-location.png"
              alt="  Visit us in Vancouver"
            />
            <Text color="#ffffff" my="3">
              Visit us in Vancouver
            </Text>
            <Text color="#ffffff" fontWeight="bold" fontSize="18px">
              58 E 2nd Ave Vancouver,
              <br />
              BC V5T 1B1
            </Text>
          </Box>
          <Box
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="flex-start"
            backgroundColor="secondary.500"
            borderRadius="8px"
            p="24px"
            textAlign="center"
            height="100%"
          >
            <img
              draggable="false"
              src="/img/ae-contact-location.png"
              alt="  Visit us in Toronto"
            />
            <Text color="#ffffff" my="3">
              Visit us in Toronto
            </Text>
            <Text color="#ffffff" fontWeight="bold" fontSize="18px">
              799 Exceller Circle Newmarket,
              <br />
              ON L3X 1P2
            </Text>
          </Box>
        </Grid>
      </Box>
    </Layout>
  );
};

export default ContactUsPage;
